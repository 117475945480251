import { createGlobalStyle } from "styled-components";
import "./font/css/general-sans.css";

export const GlobalStyle = createGlobalStyle`
 /* Colors */
 :root{
   --background: #EAF6F64D;
   --grey: #595959;
   --darkGreen:#164E73;
   --lightGreen:#2FA5A9;
   --white:#fff;
   --nav:#35353f;
   --nav2:#3f3d56;
}

@font-face {
  font-family: 'GeneralSansEL';
  src: url('font/fonts/GeneralSans-Extralight.woff2') format('woff2'),
       url('font/fonts/GeneralSans-Extralight.woff') format('woff'),
       url('font/fonts/GeneralSans-Extralight.ttf') format('truetype');
       font-weight: 300;
       font-display: swap;
       font-style: normal;
}

font-family: 'GeneralSans-Variable';
font-family: 'GeneralSans-VariableItalic';
font-family: 'GeneralSans-Extralight';
font-family: 'GeneralSans-ExtralightItalic';
font-family: 'GeneralSans-Light';
font-family: 'GeneralSans-LightItalic';
font-family: 'GeneralSans-Regular';
font-family: 'GeneralSans-Italic';
font-family: 'GeneralSans-Medium';
font-family: 'GeneralSans-MediumItalic';
font-family: 'GeneralSans-Semibold';
font-family: 'GeneralSans-SemiboldItalic';
font-family: 'GeneralSans-Bold';
font-family: 'GeneralSans-BoldItalic';

  /* Basic Styling */

  *,*::before,*::after{
    margin:0;
    padding:0;
    box-sizing:border-box;
    font-family: 'GeneralSans-Variable', sans-serif;
}

/* Hide the scrollbar */
::-webkit-scrollbar {
  display: none;
}

/* Optional: You can also hide scrollbar track and scrollbar thumb for better visual */
/* This will remove all scrollbar indicators */
::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  display: none;
}


html{
  scroll-behavior:smooth;
}
    body,
    html,
    a {
        font-family: 'GeneralSans-Regular', sans-serif;
            }
    body {

        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: var(--background);

        overflow-x: hidden;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin:0;
        padding:0;
    }
    a {

        text-decoration: none;
        outline: none;
    }
    button{
        border:none;
        outline:none;
        &:focus{
            outline:none;
        }
    }

    *:focus {
        outline: none;
    }
    img {
        width:100%;
        height: auto;
    }
`;
